<template>
    <div class="mainhistory">
        <div class="container-history">
            <h2>تاریخچه</h2>
            <ReUsableHistory />
        </div>
    </div>
</template>

<script>
import ReUsableHistory from './ReUsableHistory.vue'
    export default {
        name:'MainHistory',
        components:{
            ReUsableHistory
        }
    }
</script>

<style lang="scss" scoped>
h2{
    align-self: flex-start;
}
.mainhistory{
    display: flex;
    align-items: flex-start;
    justify-content: start;
    height: 100%;
    min-height: 92vh;
    width: 100%;
}
.container-history{
    height: 50%;
    display:flex;
    flex-direction: column;
    row-gap: 36px;
    width: 100%;
    align-items: center;
    justify-content: center;
}
</style>
<template>
    <div class="history">
        <div class="title">
            <div class="btns">
                <span @click="orderTable=false" :class="{'active-span':!orderTable}" class="activeorders"> سفارشات فعال </span>
                <span @click="orderTable=true" :class="{'active-span':orderTable}" class="tradehistory"> تاریخچه سفارشات </span>
                <div :class="orderTable ? 'ot' : 'ao'" class="underline"></div>
            </div>
        </div>
        <div class="table">
            <template v-if="filterTable.length > 0">
            <perfect-scrollbar @ps-y-reach-end="getOrders">
            <table>
                <tr class="thead">
                    <td class="remove" v-if="orderTable"> زمان </td>
                    <td> معامله </td>
                    <td> مقدار </td>
                    <td class="remove"> مجموع ({{$coinLabel['TOMAN']}}) </td>
                    <td> قیمت ({{$coinLabel['TOMAN']}}) </td>
                    <td class="remove" v-if="orderTable"> انجام شده </td>
                    <td> {{orderTable ? 'وضعیت':'عملیات'}} </td>
                </tr>
                <tr class="tbody" v-for="(item,index) in filterTable" :key="index">
                    <td class="remove" v-if="orderTable"> {{$G2J(item.createdAtDateTime)}} </td>
                    <td v-if="!orderTable"> {{ item.orderType.includes('BUY')? 'خرید':'فروش' }} </td>
                    <td :class="item.orderType.includes('BUY') ? 'success' : 'fail'" v-if="orderTable"> {{ $orderType[item.orderType] }} </td>
                    <td> {{ $toLocal(item.amount,$decimal[$route.params.tradeFrom]) }} </td>
                    <td class="remove"> {{ $toLocal(item.wholePrice,$decimal['TOMAN']) }} </td>
                    <td> {{ $toLocal(item.unitPrice,$decimal['TOMAN']) }} </td>
                    <td class="remove" v-if="orderTable"> {{ item.executedPercent }}%</td>
                    <td class="success" v-if="orderTable && item.orderStatus.includes('FINISHED')"> موفق </td>
                    <td class="fail" v-if="orderTable && item.orderStatus.includes('CANCELLED')"> لغو شده </td>
                    <td @click="candelOrder(item.id)" class="td-btn" v-if="!orderTable"> لغو سفارش </td>
                </tr>
            </table>
            </perfect-scrollbar>
            </template>
            <template v-else>
                    <div class="no-content">
                        <span> محتوایی جهت نمایش وجود ندارد </span>
                    </div>
            </template>
        </div>
    </div>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
    export default {
        name:'ReUsableHistory',
        component:{
            PerfectScrollbar
        },
        data() {
            return {
                orderTable:false,
                activeOrders:[],
                tradeHistory:[],
                page:0,
                lastPage:1,
            }
        },
        computed:{
            filterTable(){
                let data
                if(this.orderTable){
                    data=this.tradeHistory
                }else{
                    data=this.activeOrders
                }
                return data
            }
        },
        methods: {
            getActiveOrders(e){
                this.activeOrders=e.filter(a => a.orderStatus === 'IS_OPEN')
            },
            async getOrders(){
                if (this.page < this.lastPage) {
                    this.page++
                    const res = await this.$axios.get('/orders', {
                        params: {
                            page:this.page,
                            size:10,
                        }
                    })
                    this.lastPage = res.totalPages
                    let newData = res.content.filter(a => a.orderStatus !== 'IS_OPEN')
                    this.tradeHistory=[...this.tradeHistory,...newData]
                    this.getActiveOrders(res.content);
                }
            },
            async candelOrder(e) {
                this.$swal.fire({
                        title: 'لغو سفارش',
                        text: "سفارش شما لغو خواهد شد و قابل برگشت نخواهد بود ",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'بازگشت',
                        confirmButtonText: 'لفو سفارش'
                        }).then(async (result) => {
                        if (result.isConfirmed) {
                            this.state.loading = true
                            const [res,] = await this.$http.delete(`orders/${e}`)
                            console.log(res);
                            if (res) {
                                ++this.state.orderChanged
                                this.getOrders()
                                this.$swal.fire(
                                'لغو سفارش',
                                'سفارش شما با موفقیت لغو شد',
                                'success'
                                )
                            }
                        }
                 })
            },
        },
        mounted() {
            this.getOrders();
        },
    }
</script>

<style lang="scss" scoped>
.no-content{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30px;
    span{
        font-size: clamp(14px,1vw,16px);
        font-weight: 400;
        color: var(--grey);   
    }
}
.thead{
    font-size: clamp(14px,1vw,16px);
    font-weight: 400;
    color: var(--grey);
    position: sticky;
    top: 0;
    background: var(--home-background);
}
.tbody{
    font-size: clamp(12px,1vw,14px);
    font-weight: 400;
}
.td-btn{
    opacity:.5;
    cursor: pointer;
    &:hover{
        opacity: 1;
        color: var(--red);
    }
}
.table{
    height: 100%;
    width: 100%;
    overflow: scroll;
    table{
        border-collapse: collapse;
        width: 100%;
        text-align: center;
        // height: 100%;
    }
    tr{
        height: 50px !important;
        border-bottom: 1px solid var(--light-grey);
    }
}
.ot{
    left: 0px;
}
.ao{
    right: 0px;
    width: 80px !important;
}
.underline{
    width: 89px;
    height: 3px;
    border-radius: 4px;
    background: var(--primary-color);
    position: absolute;
    bottom: -4px;
}
.history{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    .title{
        background: var(--slow-yellow);
        width: 100%;
        border-radius: 4px 4px 0px 0px;
        padding: 5px 12px;
        .btns{
            display: flex;
            flex-direction: row;
            column-gap: 39px;
            position: relative;
            // justify-content: space-between;
            width: max-content;
            span{
                cursor: pointer;
                color: var(--grey);
                font-size: clamp(14px,1vw,16px);
                font-weight: 400;
            }
        }
    }

}
@media only screen and(max-width:700px){
    .remove{
        display: none;
    }
}
</style>